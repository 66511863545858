body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f8fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus {
  outline: none;
}

.tui-image-editor-container.top .tui-image-editor-controls-logo {
  display: none !important;
}

.tui-image-editor-download-btn {
  display: none !important;
}

.tui-image-editor-controls {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.tui-image-editor-container .tui-image-editor-controls-logo,
.tui-image-editor-container .tui-image-editor-controls-buttons {
  height: auto !important;
}
